import React, { Suspense, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  generatePath,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';

// Use React.lazy to lazily load components
const LazyMonitorV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.MonitorV2 })),
);
const LazyAnalyzeV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.AnalyzeV2 })),
);
const LazyInvestigate = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.Investigate })),
);
const LazyShareV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.ShareV2 })),
);
const LazyShareCollection = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.ShareCollection })),
);
const LazyAlertViewV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.AlertViewV2 })),
);
const LazyLimitsV2 = React.lazy(() =>
  import('../../../app/pages/routes').then((module) => ({ default: module.LimitsV2 })),
);

import { EnsembleErrorBanner } from '../../../app/components/header/styles';
import { Paths } from '../../../app/constants/paths';
import { EnsembleContext } from '../../../app/context/ensemble-context';
import { useTenant } from '../../../app/context/tenant-context';
import { useGetUserViewsV2 } from '../../../app/hooks/accounts';
import logo from '../../../assets/cr-logo.svg';
import { useAppContext } from '../../context/app-context';
import { useLayoutContext } from '../../context/layout-context';
import { ViewContextProvider } from '../../context/view-context';
import AlertPage from '../../pages/alert/alert';
import { TenantSelector } from '../header';
import { Loading } from '../loading/loading';
import Sidebar from '../sidebar';
import { Logo } from '../sidebar/style';

import Panel, { PanelContainer } from './Panel';
import { TabContainer } from './TabbedContainer';
import { ViewTab } from './ViewTab';

import { Icon } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { ViewType } from '@controlrooms/models';

const ViewArea = styled.div`
  position: relative;
  flex-grow: 1;
  background: ${({ theme }) => theme.investigate.backgroundColor};
`;

const PanelLayout: React.FC = () => {
  const { showEnsembleErrorBanner, setShowEnsembleErrorBanner } = useContext(EnsembleContext);
  const [searchParams] = useSearchParams();

  const { isLoading } = useGetUserViewsV2();
  return (
    <PanelContainer>
      <Panel>
        <div className="top-panel" style={{ display: 'flex' }}>
          <Logo>
            <img src={logo} />
          </Logo>
          <Panel.Left>
            <div style={{ display: 'flex' }}>
              <TenantSelector />
            </div>
          </Panel.Left>
          <Panel.Resizer />
          <Panel.Right>
            <TabContainer />
          </Panel.Right>
        </div>
        {showEnsembleErrorBanner && (
          <EnsembleErrorBanner>
            <Icon className="icon" name={ICONS.Info} />
            <span data-testid="permission-info">
              {searchParams.get('reference')
                ? `This alert was evaluated by an ensemble family that you are not currently authorized to
            see. The tags from the alert shown below are populated with anomalies from your current
            live ensemble family.`
                : `This shared view was evaluated by an ensemble family that you are not currently authorized to
            see. The view shown below are populated with anomalies from your current
            live ensemble family.`}
            </span>
            <Icon
              className="close"
              name={ICONS.Close}
              onClick={() => {
                setShowEnsembleErrorBanner(false);
              }}
            />
          </EnsembleErrorBanner>
        )}
        {isLoading && (
          <ViewArea>
            <Loading overlay small={true} />
          </ViewArea>
        )}
        {!isLoading && (
          <ViewArea className="view-area">
            <Routes>
              <Route index element={<Views />} />
              <Route
                path={'/analyze'}
                element={
                  <Suspense fallback={<Loading overlay small={true} />}>
                    <LazyAlertViewV2 />
                  </Suspense>
                }
              />
              <Route path={'/alerts'} element={<AlertPage />} />
              <Route
                path={'/limits'}
                element={
                  <Suspense fallback={<Loading overlay small={true} />}>
                    <LazyLimitsV2 />
                  </Suspense>
                }
              />
              <Route
                path={'/c/:hash'}
                element={
                  <Suspense fallback={<Loading overlay small={true} />}>
                    <LazyShareCollection />
                  </Suspense>
                }
              />
              <Route
                path={'/:hash'}
                element={
                  <Suspense fallback={<Loading overlay small={true} />}>
                    <LazyShareV2 />
                  </Suspense>
                }
              />
            </Routes>
          </ViewArea>
        )}
      </Panel>
    </PanelContainer>
  );
};

const Views = () => {
  const { viewIds } = useLayoutContext();
  const { state } = useLocation();
  return (
    <>
      {viewIds.map((viewId) => (
        <React.Fragment key={viewId}>
          {state?.viewType === 'ALERT_LINK' && state?.referenceId === viewId ? (
            <ViewContextProvider
              viewId={viewId}
              tempNode={{
                viewType: 'ALERT_LINK',
                referenceId: state.referenceId,
                state: {
                  selectedFolders: state.state.selectedFolders || [],
                },
              }}
            >
              <Suspense fallback={<Loading overlay small={true} />}>
                <ViewContentArea viewId={viewId} />
              </Suspense>
            </ViewContextProvider>
          ) : (
            <ViewContextProvider viewId={viewId}>
              <Suspense fallback={<Loading overlay small={true} />}>
                <ViewContentArea viewId={viewId} />
              </Suspense>
            </ViewContextProvider>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const ViewContentArea = ({ viewId }: { viewId: string }) => {
  const { activeModes, setActiveModes, activeView } = useLayoutContext();
  const { state } = useLocation();
  const { isTenantSwitching } = useAppContext();
  const navigate = useNavigate();
  const { tenant } = useTenant();

  const [tabListElement, setTabListElement] = useState<HTMLElement | null>(null);
  const [defaultTabListElement, setDefaultTabListElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (state) {
      setActiveModes((prev) => {
        return {
          ...prev,
          [activeView]:
            state.viewType === 'ALERT_LINK' ? ViewType.ANALYZE : (state.viewMode as ViewType),
        };
      });

      // Clear the state from current location
      navigate(generatePath(Paths.VIEWS, { tenantId: tenant }), {
        replace: true,
      });
    }
  }, [state, activeView, setActiveModes, navigate, tenant]);

  // Effect to find the target for the portal after the component has mounted
  useEffect(() => {
    const tabList = document.getElementById('tab-container');
    const defaultTabList = document.getElementById('default-tab-container');
    if (tabList && defaultTabList) {
      setTabListElement(tabList);
      setDefaultTabListElement(defaultTabList);
    }
  }, []);

  return (
    <div
      id={viewId}
      key={viewId}
      style={{
        left: 0,
        right: 0,
        position: 'absolute',
        flexGrow: 1,
        display: 'flex',
        zIndex: viewId === activeView ? 1 : -1,
        visibility: viewId === activeView ? 'visible' : 'visible',
      }}
    >
      <Sidebar />
      {tabListElement &&
        viewId !== 'default' &&
        ReactDOM.createPortal(<ViewTab view_id={viewId} />, tabListElement)}
      {defaultTabListElement &&
        viewId === 'default' &&
        ReactDOM.createPortal(<ViewTab view_id={viewId} />, defaultTabListElement)}
      {isTenantSwitching && <Loading overlay small={true} />}
      {!isTenantSwitching && (
        <>
          {activeModes[viewId] === ViewType.MONITOR && (
            <Suspense fallback={<Loading overlay small={true} />}>
              <LazyMonitorV2 />
            </Suspense>
          )}
          {activeModes[viewId] === ViewType.ANALYZE && (
            <Suspense fallback={<Loading overlay small={true} />}>
              <LazyAnalyzeV2 />
            </Suspense>
          )}
          {activeModes[viewId] === ViewType.INVESTIGATE && (
            <Suspense fallback={<Loading overlay small={true} />}>
              <LazyInvestigate />
            </Suspense>
          )}
        </>
      )}
    </div>
  );
};

export default PanelLayout;
