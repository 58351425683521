import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useCallback, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { matchPath, useLocation } from 'react-router-dom';

import { useViewContext } from '../../app-v2/context/view-context';
import { Paths } from '../constants/paths';
import { TimePresetMap } from '../constants/time-selection-form';
import { AnalyzeChartContext } from '../context/analyze-chart-context';
import { AnalyzeContext } from '../context/analyze-context';
import { AppContext } from '../context/app-context';
import { EnsembleContext } from '../context/ensemble-context';
import { MonitorContext } from '../context/monitor-context';
import { useTenant } from '../context/tenant-context';
import {
  addToSharedViews,
  deleteCollection,
  getCollection,
  getViews as getUserViews,
  patchCollection,
  removeViewFromSharedViews,
  saveCollection,
  updateCollection,
} from '../services/tenants';
import { deleteView, getView, saveView, updateView } from '../services/tenants';
import { getViews, getViewSets } from '../services/views';

import { useUserId } from './accounts';

import {
  AnalyzeSavedView,
  InvestigateSavedView,
  MonitorSavedView,
  PatchCollection,
  PersistCollection,
  PersistTimeSelection,
  PersistView,
  TimePresetKeys,
  TimePresetKeyType,
  TimeRanges,
  View,
  ViewType,
} from '@controlrooms/models';
// import { unique } from '@controlrooms/utils';

export const useGetView = (hash: string) => {
  const key = ['view', hash];
  return useQuery(key, () => getView(hash), {
    enabled: Boolean(hash),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
export const useGetCollection = (hash: string) => {
  const key = ['collection', hash];
  return useQuery(key, () => getCollection(hash), {
    enabled: Boolean(hash),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
export const useCustomGetView = (hash: string, enabled: boolean) => {
  const key = ['view', hash];
  return useQuery(key, () => getView(hash), {
    enabled,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useGetDefaultView = () => {
  return useCallback(
    (
      viewType: ViewType | undefined,
    ): AnalyzeSavedView | MonitorSavedView | InvestigateSavedView => {
      const now = dayjs();
      const monitorDefaultView: MonitorSavedView = {
        id: '1',
        type: ViewType.MONITOR,
        name: 'All Systems',
        pinnedTags: [],
        selectedFolders: [],
        showLimits: true,
        showMonitorLimits: true,
        timeSelection: {
          startTime: now.subtract(12, 'hour').toISOString(),
          endTime: now.toISOString(),
          timezone: dayjs.tz.guess(), // Default to user timezone
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: TimePresetMap.get(TimePresetKeys.LAST_TWELVE_HOURS),
        },
      };

      const analyzeDefaultView: AnalyzeSavedView = {
        id: '1',
        type: ViewType.ANALYZE,
        name: 'Default View',
        pinnedTags: [],
        selectedFolders: [],
        showLimits: true,
        showAnalyzeLimits: true,
        timeSelection: {
          startTime: now.subtract(12, 'hour').toISOString(),
          endTime: now.toISOString(),
          timezone: dayjs.tz.guess(), // Default to user timezone
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: TimePresetMap.get(TimePresetKeys.LAST_TWELVE_HOURS),
        },
      };

      const investigateDefaultView: InvestigateSavedView = {
        id: '1',
        type: ViewType.INVESTIGATE,
        name: 'Default View',
        selectedTags: [],
        selectedFolders: [],
        timeSelection: {
          startTime: now.subtract(12, 'hour').toISOString(),
          endTime: now.toISOString(),
          timezone: dayjs.tz.guess(), // Default to user timezone
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: TimePresetMap.get(TimePresetKeys.LAST_TWELVE_HOURS),
        },
      };
      const defaultViews = {
        [ViewType.MONITOR]: monitorDefaultView,
        [ViewType.ANALYZE]: analyzeDefaultView,
        [ViewType.INVESTIGATE]: investigateDefaultView,
      };

      return viewType ? defaultViews[viewType] : defaultViews[ViewType.ANALYZE];
    },
    [],
  );
};

export const useSaveCollection = (
  onSuccess?: (data: string) => Promise<unknown> | void,
  onError?: (error: AxiosError) => Promise<unknown> | void,
) => {
  const queryClient = useQueryClient();
  return useMutation((collection: PersistCollection) => saveCollection(collection), {
    onSuccess() {
      queryClient.invalidateQueries(['user-collections']);
    },
    onError,
  });
};

export const useUpdateCollection = () => {
  return useMutation(
    ({ collection_id, collection }: { collection_id: string; collection: PersistCollection }) =>
      updateCollection(collection_id, collection),
  );
};
export const usePatchCollection = () => {
  return useMutation(
    ({ collection_id, collection }: { collection_id: string; collection: PatchCollection }) =>
      patchCollection(collection_id, collection),
  );
};
export const useDeleteCollection = (onError?: (error: AxiosError) => Promise<unknown> | void) => {
  const queryClient = useQueryClient();
  return useMutation((collection_id: string) => deleteCollection(collection_id), {
    onSuccess() {
      queryClient.invalidateQueries(['user-collections']);
    },
    onError,
  });
};

export const useSaveView = (onError?: (error: AxiosError) => Promise<unknown> | void) => {
  const queryClient = useQueryClient();
  return useMutation((view: PersistView) => saveView(view), {
    onSuccess() {
      queryClient.invalidateQueries(['user-views']);
    },
    onError,
  });
};
export const useAddToSharedViews = (onError?: (error: AxiosError) => Promise<unknown> | void) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ viewId, payload }: { viewId: string; payload: { user_ids: number[] } }) =>
      addToSharedViews(viewId, payload),
    {
      onSuccess() {
        queryClient.invalidateQueries(['user-shared-views']);
      },
      onError,
    },
  );
};

export const useRemoveViewFromSharedViews = (
  onError?: (error: AxiosError) => Promise<unknown> | void,
) => {
  const queryClient = useQueryClient();
  return useMutation((view_id: string) => removeViewFromSharedViews(view_id), {
    onSuccess() {
      queryClient.invalidateQueries(['user-shared-views']);
    },
    onError,
  });
};
export const useDeleteView = (onError?: (error: AxiosError) => Promise<unknown> | void) => {
  const queryClient = useQueryClient();
  return useMutation((view_id: string) => deleteView(view_id), {
    onSuccess() {
      queryClient.invalidateQueries(['user-views']);
      queryClient.invalidateQueries(['user-collections']);
    },
    onError,
  });
};

export const useUpdateView = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ view_id, view }: { view_id: string; view: PersistView }) => updateView(view_id, view),
    {
      onSuccess() {
        queryClient.invalidateQueries(['user-views']);
      },
    },
  );
};
export const useGetAllViews = () => {
  const key = ['views'];
  return useQuery(key, () => getUserViews(), {
    enabled: true,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

const useBuildBaseView = () => {
  const { pathname } = useLocation();
  const { analyzeTimeSelection, monitorTimeSelection } = useContext(AppContext);
  const {
    viewState: { selectedEnsemble: viewEnsemble },
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const {
    selectedFolders: selectedFoldersM,
    severityFilter: severityFilterM,
    showMonitorLimits: showMonitorLimits,
  } = useContext(MonitorContext);
  const {
    selectedFolders: selectedFoldersA,
    pinnedTags,
    showAnalyzeLimits: showAnalyzeLimits,
  } = useContext(AnalyzeContext);
  const { hiddenTags } = useContext(AnalyzeChartContext);

  return useCallback(() => {
    const isAnalyze = matchPath(Paths.ANALYZE, pathname);

    const rest = isAnalyze
      ? {
          selectedFolders: selectedFoldersA,
          pinnedTags,
          hiddenTags,
          showLimits: showAnalyzeLimits,
        }
      : {
          selectedFolders: selectedFoldersM,
          severityFilter: severityFilterM,
          showLimits: showMonitorLimits,
        };

    const timeSelection = isAnalyze ? analyzeTimeSelection : monitorTimeSelection;
    const type = isAnalyze ? ViewType.ANALYZE : ViewType.MONITOR;

    return {
      type,
      timeSelection: PersistTimeSelection.ofTimeSelection(timeSelection),
      ensemble_family_id: selectedEnsemble?.family_id,
      ...rest,
    } as View;
  }, [
    analyzeTimeSelection,
    monitorTimeSelection,
    pathname,
    pinnedTags,
    selectedFoldersA,
    selectedFoldersM,
    severityFilterM,
    showMonitorLimits,
    hiddenTags,
    showAnalyzeLimits,
    selectedEnsemble,
  ]);
};

export const useBuildSavedView = () => {
  const _buildBaseView = useBuildBaseView();

  return useCallback(
    (name: string, preset: TimePresetKeyType | null) => {
      const _view = _buildBaseView();

      if (preset && TimePresetMap.get(preset)) {
        const now = dayjs();
        _view.timeSelection = {
          ..._view.timeSelection,
          startTime: now.subtract(TimePresetMap.get(preset) as number, 'seconds').toISOString(),
          endTime: now.toISOString(),
          timePreset: preset,
          timeRange: TimeRanges.PRESET,
          streamingTimeInSeconds: TimePresetMap.get(preset),
        };
      }

      return {
        ..._view,
        name: name,
        // id: unique(),
      } as MonitorSavedView | AnalyzeSavedView | InvestigateSavedView;
    },
    [_buildBaseView],
  );
};

export const useBuildSharedView = () => {
  const _buildBaseView = useBuildBaseView();
  const currentUserId = useUserId();

  return useCallback(() => {
    const _view = _buildBaseView();

    return {
      user_id: currentUserId,
      shared: true,
      view: {
        ..._view,
        name: 'shared_view_' + new Date().getTime().toString() + '_' + currentUserId,
      },
    } as PersistView;
  }, [_buildBaseView, currentUserId]);
};

export const useViewSet = () => {
  const { tenant } = useTenant();

  const key = ['viewset', tenant];
  return useQuery(key, () => getViewSets(tenant), {
    enabled: true,
    select: ({ result }) => result,
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
    staleTime: 60 * 60 * 1000, // 1 hour,
  });
};

export const useViews = (viewSetId: string) => {
  const { tenant } = useTenant();
  const key = ['view', tenant, viewSetId];
  return useQuery(key, () => getViews(viewSetId), {
    enabled: true,
    select: ({ result }) => result,
    cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
    staleTime: 60 * 60 * 1000, // 1 hour,
  });
};
