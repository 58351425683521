import { useForm } from 'react-hook-form';

import { useSaveCollection } from '../../../app/hooks/view';
import { useLayoutContext } from '../../context/layout-context';

import { FieldLabel } from './TabbedContainer';

import { Modal, ModalContents, TextInput } from '@controlrooms/components';
import { ViewShareType } from '@controlrooms/models';

export const SaveCollectionModal = ({ onClose }: { onClose: () => void }) => {
  const { setViewCollection, savedViewIds } = useLayoutContext();
  const { mutateAsync: createCollection } = useSaveCollection();

  const {
    register,
    watch,
    formState: { errors },
    trigger,
  } = useForm<{
    name: string;
    description: string;
    shareType: ViewShareType;
  }>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      shareType: ViewShareType.PRIVATE,
    },
  });

  // const shareTypeOptions = useMemo(() => {
  //   return [
  //     {
  //       value: ViewShareType.PRIVATE,
  //       label: ViewShareType.PRIVATE,
  //     },
  //     {
  //       value: ViewShareType.EDITABLE,
  //       label: ViewShareType.EDITABLE,
  //     },
  //     {
  //       value: ViewShareType.VIEWABLE,
  //       label: ViewShareType.VIEWABLE,
  //     },
  //   ];
  // }, []);

  const _createCollection = async (collection: { name: string; description: string }) => {
    const persistedCollection = {
      ...collection,
      viewsIds: savedViewIds,
    };
    try {
      const response = await createCollection({
        collection_name: persistedCollection.name,
        description: persistedCollection.description,
        view_ids: persistedCollection.viewsIds,
      });
      setViewCollection((prev) => ({
        ...prev,
        name: response.collection_name,
        viewsIds: persistedCollection.viewsIds,
        isDirty: false,
        collectionId: response.collection_id,
        views: response.views || [],
      }));
    } catch (error) {
      console.error(error);
      setViewCollection({
        name: persistedCollection.name,
        viewsIds: persistedCollection.viewsIds,
        isDirty: false,
        views: [],
      });
    } finally {
      onClose();
    }
  };

  const handleCreateCollection = () => {
    const collectionName = watch(`name`);
    const collectionDescription = watch(`description`);
    console.log(collectionName, collectionDescription);
    if (!collectionName || !collectionDescription) {
      trigger();
      return false;
    } else {
      _createCollection({ name: collectionName, description: collectionDescription });
    }
  };

  return (
    <Modal open={true}>
      <ModalContents
        title={'Save new collection'}
        styles={{ content: { maxWidth: '450px' } }}
        confirmButtonText="Save Collection"
        closeButtonText="Cancel"
        closeButtonCallback={() => {
          onClose();
        }}
        shouldCloseOnEsc={false}
        confirmButtonCallback={handleCreateCollection}
        dataTestIdCancel="modal-close-icon-delete-alert"
      >
        <div style={{ padding: '10px', fontSize: '13px' }}>
          <p>Save the views you are currently using as a Collection.</p>
          <div>
            <FieldLabel>Name</FieldLabel>
            <TextInput
              type="string"
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              className="view-name"
              label=""
              placeholder=""
              errorId={'view-name-required'}
              disabled={false}
              {...register(`name`, {
                validate: (value) => {
                  if (value.length <= 1) {
                    return 'Provide a valid view name';
                  }
                  return true;
                },
              })}
              errorMessage={errors.name?.message || ''}
            />
            <br />
            <FieldLabel>Description</FieldLabel>
            <TextInput
              type="string"
              className="view-description"
              label=""
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              placeholder=""
              errorId={'view-description-required'}
              disabled={false}
              {...register(`description`, {
                validate: (value) => {
                  if (value.length <= 1) {
                    return 'Provide a valid view description';
                  }
                  return true;
                },
              })}
              errorMessage={errors.description?.message || ''}
            />
          </div>
          {/* TODO: Implement sharing in backend */}
          {/* <div style={{ marginTop: '10px' }}>
            <FieldLabel>Sharing</FieldLabel>
            <Select
              style={{ width: '300px' }}
              options={shareTypeOptions}
              markSelectedOption
              defaultOption={{
                label: watch(`shareType`),
                value: watch(`shareType`),
              }}
              onChange={(e) => {
                setValue('shareType', e.value as ViewShareType);
              }}
            />
            {watch(`shareType`) === ViewShareType.PRIVATE && (
              <>
                <p>This collection is primarily for your own use.</p>
                <p>
                  You can still use the Share action to copy a link. This takes a snapshot of the
                  current state that others can view, minus the name and description you have
                  supplied. Any changes you make after sharing the link will not be reflected to the
                  link recipients.
                </p>
              </>
            )}
            {watch(`shareType`) === ViewShareType.VIEWABLE && (
              <>
                <p>
                  Your colleagues will see this collection in their shared list, but any changes
                  they make to it will be visible only to them. Changes you make can be published to
                  all and applied by each viewer using the Update action.
                </p>
              </>
            )}
            {watch(`shareType`) === ViewShareType.EDITABLE && (
              <>
                <p>
                  You and your colleagues can edit the collection and publish those edits using the
                  Publish action. To apply someone elses edits to you will need to use the Update
                  action.
                </p>
              </>
            )}
          </div> */}
        </div>
      </ModalContents>
    </Modal>
  );
};
